import React, { useEffect, useState } from 'react';
import { useLocation,Link, useHistory, useParams } from 'react-router-dom';
import CenterContainer from './center_container';
import Navigator from './components/navigator';
import MobileMockup from './gimme/mobile_mockup';
import Cookies from 'universal-cookie';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {GALLERY_STORE} from '../config';

import {API_GET_INTERACTIVE, API_DELETE_CLIENT, API_DELETE_INTERACTIVE} from '../config';

import ModifyInteractive from './gimme/modify_interactive';
import ModifyClient from './gimme/modify_client';
import ConfirmModal from './components/confirm_modal';

export async function GetInteractiveData(id, history) {
    const cookies = new Cookies();
    const username = cookies.get('username');
    const pw = cookies.get('password');
    const url = new URL(API_GET_INTERACTIVE, window.location.origin); // Ensure URL is correctly instantiated

    url.searchParams.append('username', username);
    url.searchParams.append('password', pw);
    url.searchParams.append('interactive_id', id);
    try {
        const res = await fetch(url, {
            method: 'GET',
        });

        const resData = await res.json().catch((error) => 
            console.log('An error occurred ', error)
        );
        if (resData) {
            if (res.status === 401) {
                // Redirect to /home
                history.push('/home');   
                return null;
            }
            //console.log(resData);
            return resData;
        }
        return null;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
}

function splitItems(str) {
    if (!str) return [];
    return str.split('||');
}

export function ParseInteractiveData(data) {
    //console.log(data);
    const newData = {...data};

    newData.interactive.accessibility_features = splitItems(data.interactive.accessibility_features);
    newData.interactive.assets_required = splitItems(data.interactive.assets_required);
    newData.interactive.asset_details = JSON.parse(data.interactive.asset_details);
    newData.clients = Object.values(data.clients);

    newData.clients.forEach(client => {
        // Split develop_time from "A||B" to {value: A, unit: B}
        const [value, unit] = client.data.develop_time.split('||');
        client.data.develop_time = {
            value: value ? value : 0,
            unit: unit ? unit : '',
        };
        
        // Split dwell_time from "A||B" to {value: A, unit: B}
        const [value2, unit2] = client.data.dwell_time.split('||');
        client.data.dwell_time = {
            value: value2 ? value2 : 0,
            unit: unit2 ? unit2 : '',
        };
    });

    return newData;
}


export default function Gimme(props){
    const {canEdit} = props;
    const location = useLocation();
    const [selectedClient, setSelectedClient] = useState(0);
    const [assetHovered, setAssetHovered] = useState(false);
    const [showModifyInteractiveModal, setShowModifyInteractiveModal] = useState(false);
    const [showModifyClientModal, setShowModifyClientModal] = useState(false);
    const [showCreateClientModal, setShowCreateClientModal] = useState(false);

    const [showDeleteInteractiveModal, setShowDeleteInteractiveModal] = useState(false);
    const [showDeleteClientModal, setShowDeleteClientModal] = useState(false);
    const [error, setError] = useState('');
    const [processing, setProcessing] = useState(false);

    const history = useHistory();

    const {id} = useParams();
    const [interactiveData, setInteractiveData] = useState(null);
    const [routes, setRoutes] = useState([]);

    async function deleteInteractive () {
        setProcessing(true);

        const cookies = new Cookies();
        const formData = new FormData();
        formData.append('adminAccount', cookies.get('adminAccount'));
        formData.append('adminPassword', cookies.get('adminPassword'));
        formData.append('id', id);
        
        try {
            const response = await fetch(API_DELETE_INTERACTIVE, {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }
            
            const responseData = await response.json();
            console.log('Interactive deleted successfully:', responseData);
            fetchData();
            setShowDeleteInteractiveModal(false);
            history.push('/home');
            // Handle successful deletion (e.g., redirect, update state, etc.)
        } catch (error) {
            console.error('Error deleting interactive:', error);
            setError(error.message);
            // Handle error (e.g., show an error message to the user)
        } finally {
            setProcessing(false);
        }
    }

    async function deleteClient (clientId) {
        setProcessing(true);

        const cookies = new Cookies();
        const formData = new FormData();
        formData.append('adminAccount', cookies.get('adminAccount'));
        formData.append('adminPassword', cookies.get('adminPassword'));
        formData.append('id', clientId);
        
        try {
            const response = await fetch(API_DELETE_CLIENT, {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }
            
            const responseData = await response.json();
            console.log('Client deleted successfully:', responseData);
            fetchData();
            setShowDeleteClientModal(false);
            // Handle successful deletion (e.g., redirect, update state, etc.)
        } catch (error) {
            console.error('Error deleting client:', error);
            setError(error.message);
            // Handle error (e.g., show an error message to the user)
        } finally {
            setProcessing(false);
        }
    }

    const fetchData = async () => {
        try {
            const data = await GetInteractiveData(id, history);
            if (data) {
                const parsedData = ParseInteractiveData(data);
                setInteractiveData(parsedData);
                setRoutes([
                    { text: 'MENU', path: '/home' },
                    { text: parsedData.interactive.type, path: location.pathname }
                ]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    return (
        <>
        <CenterContainer>
            <Container fluid className="d-flex flex-column">
                <Row>
                    <Col xs={7}>
                        <div className='d-flex flex-row align-items-end'>
                            <Navigator className="mt-4" routes={routes} />
                            {canEdit && <div className="icon-container ml-3">
                                <i className="fas fa-edit" onClick={()=>setShowModifyInteractiveModal(true)} style={{ cursor: 'pointer', marginRight: '10px' }}></i>
                                <i className="fas fa-trash" onClick={()=>{setShowDeleteInteractiveModal(true)}} style={{ cursor: 'pointer' }}></i>
                            </div>}
                        </div>
                        
                        <h1 style={{minHeight:"80px"}} className="main-header">{
                            !interactiveData ? "Loading..." :
                            interactiveData.interactive.interactive_name
                        }</h1>
                       
                        <Row>
                            <Col xs={6}>
                            <div className="grey-top-border w-100"></div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <h2 className="sub-header">What is this about?</h2>
                                <p className="info-text" style={{marginBottom:"32px"}}>{
                                    !interactiveData ? "Loading..." :
                                    interactiveData.interactive.description
                                }</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={6}>
                                <div className="grey-top-border w-100">
                                    <h2 className="sub-header mb-4">Accessibility features</h2>
                                    {
                                        !interactiveData ? "Loading..." :
                                        <ul className='ul-no-padding'>
                                            {interactiveData.interactive.accessibility_features.map((feature, index) => (
                                                <li key={`feature-`+index} style={{marginTop:"2px"}}className="info-text">{feature}</li>
                                            ))}
                                        </ul>
                                    }
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="grey-top-border w-100">
                                <Link className="no-hover-underline" to={location.pathname + "/assets"}
                                 style={{ display: 'flex', alignItems: 'center' }}
                                 onMouseEnter={() => setAssetHovered(true)}
                                 onMouseLeave={() => setAssetHovered(false)}
                                 >
                                    <h2 style={{ textDecoration: "underline solid #0E0E0E 2px", marginRight: '14px' }} className="sub-header mb-4">Assets required</h2>
                                    <i className={`fas fa-chevron-right d-flex justify-content-center align-items-center mt-2 green-background-circle ${assetHovered ? 'active' : ''}`}></i>
                                </Link>
                                {
                                    !interactiveData ? "Loading..." :
                                    <ul className='ul-no-padding'>
                                        {interactiveData.interactive.assets_required.map((asset, index) => (
                                            <li key={`asset-`+index} style={{marginTop:"2px"}}className="info-text">{asset}</li>
                                        ))}
                                    </ul>
                                }

                                </div>
                            </Col>
                        </Row>
                        
                    </Col>
                    <Col className="d-flex justify-content-end" xs={{span:5}}>
                        <MobileMockup className="mt-5" items={ !interactiveData? [] : interactiveData.gimme_carousel_items} />
                    </Col>
                </Row>

                <Row style={{marginTop:"80px"}}>
                    <Col className="d-flex justify-content-center">
                        <h2 className='main-header'>How Others Has Used It</h2>
                    </Col>
                </Row>

                <Row className="mt-5">
                    {
                        !interactiveData ? <Col className="barnd-box"></Col> :
                        interactiveData.clients.map((client, index) => (
                            <Col key={`brand-`+index} className="brand-box" onClick={()=> setSelectedClient(index)} >
                                <img src={GALLERY_STORE+`${client.data.logo}`} alt="Brand logo" />
                                <i className={`fas fa-caret-up ${selectedClient === index ? 'active' : ''}`}></i>
                            </Col>
                        ))
                    }
                
                    {/* <Col xs={4} className="brand-box">
                        <img src={artwork_logo} alt="Artwork logo" />
                    </Col>
                    <Col xs={4} className="brand-box"></Col>
                    <Col xs={4} className="brand-box"></Col> */}
                </Row>
                <Row className="mt-0">
                    <Col className="d-flex flex-column align-items-center pl-0 pr-0">
                        <div className="client-info-box w-100">
                            <div className="icon-container ml-3" style={{position:"absolute", right:"10px", top:"10px"}}>
                            {canEdit && interactiveData && interactiveData.clients.length > 0 &&
                                <>
                                <i className="fas fa-edit" onClick={()=>{setShowModifyClientModal(true);}} style={{ cursor: 'pointer'}}></i>
                                <i className="fas fa-trash ml-2" onClick={()=>setShowDeleteClientModal(true)} style={{ cursor: 'pointer' }}></i>
                                </>
                            }        
                            { canEdit &&
                                <i className="fas fa-plus ml-2" onClick={()=>{setShowCreateClientModal(true);}} style={{ cursor: 'pointer' }}></i>
                            }
                            </div>
                            

                            {
                                interactiveData && interactiveData.clients.length > 0 && 
                                <Row style={{paddingTop:"40px",paddingLeft:"55px"}}>
                                <Col xs={7} className="d-flex flex-column">
                                    <div className="d-flex align-items-end">
                                        <span className="theme-label">
                                            THE CLIENT
                                        </span>
                                        <span className="medium-black-text ml-2">
                                            {!interactiveData ? "Loading..." : interactiveData.clients[selectedClient].data.name}
                                        </span>

                                        <span style={{marginLeft:"70px"}} className="theme-label">TARGET AUDIENCE</span>
                                        <span className="medium-black-text ml-2">
                                            {!interactiveData ? "Loading..." : interactiveData.clients[selectedClient].data.target_audience}
                                        </span>
                                    </div>

                                    <div style={{marginTop:"80px"}} className="pr-5">
                                        <h3 className='medium-grey-text'>How did they use the template?</h3>
                                        <p className='info-text'>
                                            {!interactiveData ? "Loading..." : interactiveData.clients[selectedClient].data.template_usage}                                 
                                        </p>
                                    </div>


                                <div className='d-flex flex-column justify-content-end flex-grow-1'>

                                        <div style={{}} className='w-100 d-flex justify-content-between align-items-end'>
                                        <div>
                                            <span className='main-header highlighted-text'>
                                            {!interactiveData ? "" : (
                                                <>
                                                    {interactiveData.clients[selectedClient].data.develop_time.value}
                                                    <br />
                                                    {interactiveData.clients[selectedClient].data.develop_time.unit}
                                                </>
                                            )}
                                            </span>
                                            <br/>
                                            <span className='theme-label'>To Develop</span>
                                        </div>
                                        <div>
                                            <span className='main-header highlighted-text'>
                                                {!interactiveData ? "" : (
                                                    <>
                                                        {interactiveData.clients[selectedClient].data.dwell_time.value}
                                                        <br />
                                                        {interactiveData.clients[selectedClient].data.dwell_time.unit}
                                                    </>
                                                )}
                                            </span>
                                            <br/>
                                            <span className='theme-label'>Dwell Time</span>
                                        </div>
                                        <div>
                                            <span className='main-header highlighted-text'>
                                                {!interactiveData ? "" : interactiveData.clients[selectedClient].data.interactions_registered}%
                                            </span>
                                            <br/>
                                            <span className='theme-label'>Interactions Registered</span>
                                            </div>
                                        </div>
                                    
                                </div>

                                </Col>
                                <Col className="d-flex justify-content-center" xs={{span:5}}>
                                    <MobileMockup width="245px" items={!interactiveData? [] : interactiveData.clients[selectedClient].carousel_items} />
                                </Col>
                            </Row>
                            }
                            
                        </div>

                        <button className='standard-button mt-5 mb-5' onClick={() => history.push('/home')}>Back</button>

                    </Col>
                </Row>

            </Container>
        </CenterContainer>

        {/* <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this interactive?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={()=> console.log("Delete WIP")}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal> */}
        <ConfirmModal
            show={showDeleteInteractiveModal}
            onHide={() => {setShowDeleteInteractiveModal(false); setError('')}}
            onConfirm={deleteInteractive}
            text="Are you sure you want to delete this interactive?"
            error={error}
            processing={processing}
            />

        <ConfirmModal 
            show={showDeleteClientModal}
            onHide={() => {setShowDeleteClientModal(false); setError('')}}
            onConfirm={()=>deleteClient(interactiveData.clients[selectedClient].data.id)}
            text="Are you sure you want to delete this client?"
            error={error}
            processing={processing}
            />

      {
        interactiveData && 
        <ModifyInteractive
        interactiveId={id} 
        show={showModifyInteractiveModal}
        onHide={() => setShowModifyInteractiveModal(false)}
        interactive={interactiveData.interactive}
        carouselItems={interactiveData.gimme_carousel_items}
        onUpdate={fetchData}
        ></ModifyInteractive>
      }

      {
        interactiveData && interactiveData.clients && interactiveData.clients[selectedClient] &&
        <ModifyClient
        interactiveId={id} 
        show={showModifyClientModal}
        onHide={() => setShowModifyClientModal(false)}
        clientData={interactiveData.clients[selectedClient].data}
        carouselItems={interactiveData.clients[selectedClient].carousel_items}
        onUpdate={fetchData}
        usage={"update"}
        ></ModifyClient>
      }

    {
        interactiveData && 
        <ModifyClient
        interactiveId={id} 
        show={showCreateClientModal}
        onHide={() => setShowCreateClientModal(false)}
        onUpdate={fetchData}
        usage={"create"}
        ></ModifyClient>
      }

    </>
    );
}