import React, { useEffect, useState } from 'react';
import {joinWithSeparator} from "./interactive_data"
import {Modal, Form, Row, Col, Button, InputGroup} from 'react-bootstrap';
import UploadImageContainer from '../components/upload_image_container';
import {createLocalUrl} from '../utils/utils';
import ConfirmModal from '../components/confirm_modal';
import ModifyCarousel from './modify_carousel';
import { API_UPDATE_CLIENT} from '../../config';
import Cookies from 'universal-cookie';

const emptyData = {
    id: -1,
    name: '',
    target_audience: '',
    logo: {url: null, file: null},
    template_usage: '',
    develop_time: {value: 0, unit: ''},
    dwell_time: {value: 0, unit: ''},
    interactions_registered: 0,
    carousel_items: []
}

//usage is currently unused
export default function ModifyClient({interactiveId, show=true, onHide=()=>{}, clientData = emptyData, carouselItems=[], onUpdate, usage}){
    const [data, setData] = useState({...clientData, logo: {url: null, file: null}});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [operation, setOperation] = useState();
    const [processing, setProcessing] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        setData({...clientData, logo: {url: null, file: null}});
    }, [clientData]);

    const updateData = (field, value) => {
        setData({...data, [field]: value});
    }

    //if id != -1, then update client, otherwise create client
    async function uploadClientData(id=-1){
        //const isCreate = id==-1;

        const api =  API_UPDATE_CLIENT; // isCreate ? API_CREATE_CLIENT function is merged to the same php file

        setProcessing(true);

        try {
          const cookies = new Cookies();

          const formData = new FormData();
            formData.append('id', id);
            formData.append('adminAccount', cookies.get('adminAccount'));
            formData.append('adminPassword', cookies.get('adminPassword'));
            formData.append('interactive_id', interactiveId);
            formData.append('name', data.name);
            formData.append('target_audience', data.target_audience);
            formData.append('template_usage', data.template_usage);
            formData.append('develop_time', joinWithSeparator(data.develop_time.value, data.develop_time.unit));
            formData.append('dwell_time', joinWithSeparator(data.dwell_time.value, data.dwell_time.unit));
            formData.append('interactions_registered', data.interactions_registered);
          if(data.logo && data.logo.file)
            formData.append('logo', data.logo.file);

        
          const response = await fetch(api, {
              method: 'POST',
              body: formData
          });
  
          if (!response.ok) {
              // Handle HTTP errors
              const errorText = await response.text();
              throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
          }
  
          const responseData = await response.json();
          console.log('Success:', responseData);
          onUpdate();
          updateData('logo', {url: null, file: null});
          setShowConfirmModal(false);
          myOnHide();
          // Handle success (e.g., show a success message, update state, etc.)
      } catch (error) {
          console.error('Error updating client:', error);
          setErrMsg(error.message);
          // Handle error (e.g., show an error message to the user)
      } finally {
          setProcessing(false);
      }  
    }

    const myOnHide = () => {
        updateData('logo', {url: null, file: null});
        onHide();
    };

    // useEffect(() => {
    //     setData();
    // }, [clientData]);

    if(data === null){
        return <></>;
    }

    return (<>
        <Modal size="lg"  show={show} onHide={myOnHide}>
            <Modal.Header closeButton>
                <Modal.Title>{usage=="update" ? "Update Client" : "Create Client"}</Modal.Title>
            </Modal.Header>

            <Modal.Body>

            <Form>
            <Row>
                <Col>
                <Form.Group controlId="clientName">
                <Form.Label>Client Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter client name"
                    value={data.name}
                    onChange={(e) => updateData('name', e.target.value)}
                />
                </Form.Group>
                </Col>
                <Col>
                <Form.Group controlId="targetAudience">
                    <Form.Label>Target Audience</Form.Label>
                    <Form.Control
                    type="text"
                    placeholder="Enter target audiences"
                    value={data.target_audience}
                    onChange={(e) => updateData('target_audience', e.target.value)}
                    />
                </Form.Group>
                </Col>
            </Row>


            <UploadImageContainer 
                label= {usage=="create" ? "Client Logo" : "New Client Logo (Optional)"} 
                id={`clientLogo`} 
                accept='image/*' 
                imgData={data.logo && data.logo.url} 
                onChangeImg={(e) => updateData('logo', {file: e.target.files[0], url: createLocalUrl(e.target.files[0])})} 
                />
            {/* <Form.Group controlId="clientLogo">
            <Form.Label>Client Logo</Form.Label>
            <Form.Control
                type="file"
                onChange={(e) => onChange('logo', e.target.files[0])}
            />
            </Form.Group> */}



            <Form.Group controlId="templateUsage">
            <Form.Label>Template Usage</Form.Label>
            <Form.Control
                as="textarea"
                type="text"
                placeholder="Enter template usage"
                value={data.template_usage}
                onChange={(e) => updateData('template_usage', e.target.value)}
            />
            </Form.Group>
            <Form.Group as={Row}>
            <Col>
                <Form.Label>Time to Develop</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="Enter value"
                    value={data.develop_time.value || 0}
                    onChange={(e) =>
                        updateData('develop_time', { ...data.develop_time, value: e.target.value })
                    }
                />
            </Col>
            <Col>
                <Form.Label>Unit</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter unit"
                    value={data.develop_time.unit || ''}
                    onChange={(e) =>
                        updateData('develop_time', { ...data.develop_time, unit: e.target.value })
                    }
                />
            </Col>
            </Form.Group>

            <Form.Group as={Row}>
            <Col>
                <Form.Label>Dwell Time</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="Enter value"
                    value={data.dwell_time.value || ''}
                    onChange={(e) =>
                        updateData('dwell_time', { ...data.dwell_time, value: e.target.value })
                    }
                />
            </Col>
            <Col>
                <Form.Label>Unit</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter unit"
                    value={data.dwell_time.unit || ''}
                    onChange={(e) =>
                        updateData('dwell_time', { ...data.dwell_time, unit: e.target.value })
                    }
                />
            </Col>
        </Form.Group>

            <Form.Group controlId="interactionsRegistered">
            <Form.Label>Interactions Registered (%)</Form.Label>
            <Form.Control
                type="number"
                placeholder="Enter percentage"
                value={data.interactions_registered}
                onChange={(e) => updateData('interactions_registered', e.target.value)}
            />
            </Form.Group>
            
            <div className='w-100 d-flex justify-content-center'>
                <Button className='standard-button' onClick={() => {setOperation(()=>()=>uploadClientData(data.id)); setShowConfirmModal(true)}}>
                    Submit
                </Button>
            </div>

            </Form>
            </Modal.Body>

            {usage=="update" && (
                <>
                <Modal.Header>
                    <Modal.Title>Update Carousel</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <ModifyCarousel 
                        ownerType="client" 
                        ownerId={data.id}
                        carouselItems={carouselItems}
                        onCarouselUpdate={onUpdate}
                        />
                </Modal.Body>
                </>
            )}

            <Modal.Footer>
                <Button variant="secondary" onClick={myOnHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

        <ConfirmModal
        show={showConfirmModal}
        onHide={() => {setShowConfirmModal(false); setErrMsg('');}}
        onConfirm={operation}
        text="Are you sure you want to perform this operation?"
        processing={processing}
        error={errMsg}
        ></ConfirmModal>
    </>);
}