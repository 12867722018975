import React,{useState} from "react";
import Col from 'react-bootstrap/Col';
//name,
export default function Thumbnail({image,gif,onClick,type, className='', style={}}){ 

    const [hovering, setHovering] = useState(false);

    return (
        <Col xs={4} className={`pl-2 pr-2 ${className}`.trim()} style={style}>
            {/* <span className='sub-header'>{name}</span> */}
            <div className="thumbnail-wrapper mt-2">
            <div 
            onMouseEnter={()=>{setHovering(true)}} 
            onMouseLeave={()=>{setHovering(false)}} 
            onClick={onClick} 
            className={`image-wrapper clickable`}>
                <img src={image} alt={"thumbnail"}></img>
                {hovering&&gif?
                <img className={` animate__animated animate__fadeIn animate__faster`} src={gif} alt="gif"></img>
                :
                <></>
                }            
            </div>
            <span className="small-grey-text text-uppercase mt-2">
                {type}
            </span>
        </div>
        </Col>
        
    )
}