import React, {useState, useEffect} from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import CenterContainer from './center_container';
import Navigator from './components/navigator';
import {phone_frame, phone_dummy} from '../static/images'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ModifyInteractive from './gimme/modify_interactive';

import { GALLERY_STORE } from '../config';

import {GetInteractiveData, ParseInteractiveData} from "./gimme"
export default function AssetsPage(props){
    const {canEdit} = props;
    const [showEditModal, setShowEditModal] = useState(false);
    const [processing, setProcessing] = useState(false);

    const location = useLocation();
    const history = useHistory();

    const pathname = location.pathname;
    const segments = pathname.split('/').filter(segment => segment.length > 0);
    segments.pop();
    const parentPath = '/' + segments.join('/');

    const {id} = useParams();
    const [interactiveData, setInteractiveData] = useState(null);
    const [routes, setRoutes] = useState([]);

    const fetchData = async () => {
        try {
            const data = await GetInteractiveData(id, history);
            if (data) {
                const parsedData = ParseInteractiveData(data);
                setInteractiveData(parsedData);
                setRoutes([
                    { text: 'MENU', path: '/home' },
                    { text: data.interactive.type, path: parentPath },
                    { text: 'ASSETS REQUIRED', path: pathname }
                ]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
      };
    useEffect(() => {
          fetchData();
    }, [id]);

    return (
        <>
        <CenterContainer>
            <Container fluid className="d-flex flex-column">
                <Row>
                    <Col xs={7}>
                        <div className='d-flex flex-row align-items-end'>
                            <Navigator className="mt-4" routes={routes} />
                            {canEdit && <div className="icon-container ml-3">
                                <i className="fas fa-edit" onClick={()=>{setShowEditModal(true)}} style={{ cursor: 'pointer', marginRight: '10px' }}></i>
                            </div>}
                        </div>
                        <h1 style={{height:"80px"}} className="main-header">
                            { !interactiveData ? 'Loading...' : interactiveData.interactive.interactive_name}
                        </h1>
                        
                        <Row className="mt-5">
                            {!interactiveData ? 'Loading...' : interactiveData.interactive.asset_details.map((asset, index) => {
                                return (
                                    <Col xs={6} key={index}>
                                        <div className="grey-top-border w-100">
                                            <GreenCircleHeader index={index + 1} header={asset.name} />
                                            <p className="info-text mt-0">
                                                {asset.description}
                                            </p>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>

                        
                    </Col>
                    <Col xs={{span:5}} className="d-flex justify-content-end">
                        <div className='mobile-asset-wrapper mt-5'>
                                <img className='mobile-frame' src={phone_frame}></img>
                                <img className='mobile-content' src={ !interactiveData ? '' : `${GALLERY_STORE}${interactiveData.interactive.game_image}`}></img>
{/* 
                                <div className='mobile-content-container'>
                                </div> */}
                        </div>
                        
                    </Col>

                    <Col xs={12} className="d-flex justify-content-center mt-4">
                        <button className='standard-button mt-5 mb-5' onClick={() => history.goBack()}>Back</button>
                    </Col>
                </Row>


            </Container>
        </CenterContainer>
        
        {
            interactiveData && 
            <ModifyInteractive
            interactiveId={id} 
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            interactive={interactiveData.interactive}
            carouselItems={interactiveData.gimme_carousel_items}
            onUpdate={fetchData}
            processing={processing}
            ></ModifyInteractive>
      }
    </>
    );
}

function GreenCircleHeader(props){
    const {index, header} = props;

    return <div className='d-flex'>
        <div className='d-flex justify-content-center align-items-center'>
            <div className="green-circle ">
                <span className='sub-header mt-0'>{index}.</span>
                
            </div>
            <h2 className="sub-header mb-4">{header}</h2>
        </div>
    </div>
}